<template>
  <div>
    <div class="row mt-2">
      <div class="col-xl-12">
        <div class="row pl-3">
          <div v-for="filter in filterDataObj.filters" :key="filter.id" class="col-xl-2 col-md-4 mb-2 pl-0">
            <span class="c-toolbar__state-title u-text-capitalize">{{ filter.title }}</span>

            <!-- There are two types of dropdown, one that uses guid and the other that uses id -->
            <v-select
              v-if="filter.uxtype === 'dropdown' && filter.isfilterByGuid"
              :options="filter.data"
              label="displayName"
              :multiple="true"
              @input="onChange($event, filter)"
              :id="$route.name + `-filter-by-select-box-${filter.name}`"
            ></v-select>

            <v-select
              v-else-if="filter.uxtype === 'dropdown' && !filter.isfilterByGuid"
              :options="filter.data"
              label="displayName"
              :multiple="true"
              @input="onChange($event, filter)"
              :id="$route.name + `-filter-by-select-box-${filter.name}`"
            ></v-select>

            <input v-if="filter.uxtype === 'textbox'" class="form-control" type="text" @input="onTextChange($event, filter)" :id="$route.name + `-filter-by-input-box-${filter.name}`" />
          </div>

          <!-- <span class="c-toolbar__state-title u-text-capitalize d-block">Days</span>
          <v-select v-if="$isDev && $route.name === 'accounts-receivable'" label="displayName" :multiple="true">
          </v-select> -->

          <div class="col">
            <span class="c-toolbar__state-title u-text-capitalize opacity-0 d-block">.</span>
            <button id="btn-download-excel" class="btn c-btn-outlined-info btn-sm" @click.prevent="downloadExcel"><i class="fa fa-download u-text-large"></i></button>
          </div>
          <div class="col-xl-2 col-lg-2 col-sm-6" v-if="filterDataObj.hasAdvancedFilter">
            <span class="c-toolbar__state-title u-text-capitalize opacity-0">.</span>
            <a class="btn ss-warning-bg ss-white-font-color u-border-zero d-block" href="#!"> <i class="fa fa-gear u-mr-xsmall"></i>Advance </a>
          </div>
        </div>

        <div class="row" v-if="$isDev && $route.name === 'accounts-receivable'">
          <div class="mx-3 mt-2">
            <b-form-group class="mb-0 custom-group-radio-buttons technician-dashboard-group-buttons" v-slot="{ariaDescribedby}">
              <b-form-radio-group id="btn-radios-1" button-variant="outline-primary" v-model="selectedRadio" :options="options" :aria-describedby="ariaDescribedby" name="radios-btn-default" buttons>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <!-- <div class="col-2">
            <b-dropdown split ref="dropdown" size="md" class="ml-1 mr-2 mt-2 split-drop-down split-drop-down-info">
              <template v-slot:button-content> <span class="fa fa-plus mr-2"></span> Action </template>
              <b-dropdown-item>
              </b-dropdown-item>
            </b-dropdown>
          </div> -->
        </div>

        <!-- <div class="row mt-3">
          <div class="col-lg-2 col-md-4">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                checked
                id="radio1"
                name="radios"
                type="radio"
              />
              <label class="c-choice__label mb-0" for="radio1">Priority</label>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio2"
                name="radios"
                type="radio"
              />
              <label class="c-choice__label mb-0" for="radio2"
                >Created Date</label
              >
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio3"
                name="radios"
                type="radio"
              />
              <label class="c-choice__label mb-0" for="radio3"
                >Completed Date</label
              >
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    props: {
      filterDataObj: {
        type: Object,
        required: true,
      },
      dataObj: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        queryFilter: {},
        pageTitle: null,
        selectedRadio: "thismonth",
        options: [
          {text: "Today", value: "today"},
          {text: "Yesterday", value: "yesterday"},
          {text: "Last Week", value: "lastweek"},
          {text: "This Week", value: "thisweek"},
          {text: "Last Month", value: "lastmonth"},
          {text: "This Month", value: "thismonth"},
          {text: "This Year", value: "thisyear"},
          {text: "Last Year", value: "lastyear"},
        ],
      };
    },
    methods: {
      onTextChange(event, filter) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        //Wait 500ms after user finishes typing
        this.timer = setTimeout(() => {
          //Get text value
          if (filter.query) {
            if (event.target.value) {
              this.queryFilter[filter.query] = event.target.value;
            } else {
              delete this.queryFilter[filter.query];
            }
          } else {
            this.queryFilter[filter.name] = event.target.value;
          }

          this.updateFilter(this.queryFilter);
        }, 500);
      },
      onChange(event, filter) {
        let val = "";

        //Compose the query string
        val = this.extractQueryString(event, filter);

        //Store query filters in an object
        if (filter.query) {
          if (val) {
            this.queryFilter[filter.query] = val;
          } else {
            delete this.queryFilter[filter.query];
          }
        } else {
          this.queryFilter[filter.name] = val;
        }

        this.updateFilter(this.queryFilter);
      },
      extractQueryString(event, filter) {
        let param = "";
        //Concat each selected value with a ","
        event.forEach((val) => {
          //Some dropdown need to filter by the text display value,
          //while others are filtered by the selected id
          if (filter.isfilterByDisplayName) {
            //Get the dropdown value display value
            param += val.displayName + ",";
          } else if (filter.isfilterByGuid) {
            param += val.guid + ",";
          } else {
            //Get the dropdown value id value
            param += val.id + ",";
          }
        });

        //Remove the trailing "," from the end of the sring
        if (param) param = param.slice(0, -1);

        return param;
      },
      updateFilter(filter) {
        //Update current store filter with new paramaters, so the appropriate list page could handle
        this.$store.dispatch("setFilter", filter);
      },
      async downloadExcel() {
        let options = {...this.queryFilter, export: "excel", actionId: this.dataObj.actionId};
        if (this.dataObj && this.dataObj.settings && (this.dataObj.settings.clientType === "Lead" || this.dataObj.settings.clientType === "Prospect")) {
          options.only = this.dataObj.settings.clientType === "Lead" ? "ClientType:Lead" : "ClientType:Prospect";
        }
        const url = await global.getTableDataByActionId(options);
        if (url) window.open(url, "_blank");
      },
    },

    mounted() {
      this.pageTitle = this.$route.meta.gtm;
    },
    beforeDestroy() {
      this.updateFilter(null);
    },
  };
</script>
