<template>
  <div class="col-md-12 px-0">
    <b-card no-body>
      <!-- More Options Section -->
      <div v-if="(listDataObj.table.actions && listDataObj.table.actions.isEnabled) || $isDev">
        <vue-accordion class="styled-accordion styled-accordion-dash u-border" animatedClass="animated-wrapper">
          <vue-accordion-item class="activeClass u-mt-xsmall u-inline">
            <a class="primary-link link" id="toggleMoreOption" slot="title" @click="toggle" ref="toggleMoreOptions">
              <span v-if="isClose === true" class="u-text-bold"> <i class="fa fa-plus u-text-small"></i> More options </span>
              <span v-if="isClose === false" class="u-text-bold"> <i class="fa fa-minus u-text-small"></i> hide options </span>
            </a>

            <div slot="content" class="item-content">
              <div class="col-md-12 u-pl-zero">
                <div class="row p-2">
                  <div class="ss--settings-module col-md-3 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Choose an Action</span>
                    <div class="c-field">
                      <v-select v-model="selectedAction" :options="actionList" :value="selectedActionId" @input="onActionSelected" code="id" label="displayName"></v-select>
                    </div>
                  </div>

                  <!-- Dispatching Functionality -->
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-3 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Choose a Technician</span>
                    <div class="c-field">
                      <v-select v-model="selectedTechnician" :options="technicianList" :value="selectedActionId" @input="onTechnicianSelected" code="id" label="displayName"></v-select>
                    </div>
                  </div>
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title">Dispatch Date</span>
                    <div class="c-field">
                      <date-picker name="txtDispatchDate" v-model="dispatchDate" format="DD-MMM-YYYY" value-type="format" style="width: 100%" lang="en" placeholder></date-picker>
                    </div>
                  </div>
                  <div v-if="this.isDispatching" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title u-text-capitalize">
                      <br />
                    </span>
                    <div class="c-field">
                      <button @click="dispatchTechnicianAction" class="btn ss-info-bg ss-white-font-color u-border-zero u-width-100" href="#!">
                        <span class="spanSave"> <i class="fa fa-truck u-mr-xsmall ss-white-font-color"></i>Dispatch </span>
                        <span class="spanLoading">
                          <div class="spinner-grow"></div>
                          Dispatching...
                        </span>
                      </button>
                      <span class="text-success" v-show="dispatchResponseVisible">{{ this.displayDispatchResponse }}</span>
                    </div>
                  </div>
                  <!-- //END Dispatching Functionality -->

                  <!-- Export Functionality -->
                  <div v-if="this.isExporting" class="ss--settings-module col-md-2 u-mb-xsmall">
                    <span class="c-toolbar__state-title u-text-capitalize">
                      <br />
                    </span>
                    <div class="c-field">
                      <button @click="onDownloadFile" class="btn ss-info-bg ss-white-font-color u-border-zero u-width-100" href="#!">
                        <span class="spanSave"> <i class="fa fa-file u-mr-xsmall ss-white-font-color"></i>Download File </span>
                      </button>
                    </div>
                  </div>
                  <div v-if="this.isExporting" class="ss--settings-module col-md- u-mb-xsmall">
                    <i v-if="this.isExportingStarted" class="fa fa-cog fa-spin fa-3x fa-fw" style="font-size: 1.8em; margin-top: 30px"></i>
                  </div>
                  <div v-if="this.isExportingStarted" class="ss--settings-module col-md-2 u-mb-xsmall" style="margin-top: 30px">
                    {{ this.exportFileResponse }}
                  </div>
                  <!-- //END Export Functionality -->
                </div>
                <!-- end table -->
              </div>
              <!-- col-md-12 -->
            </div>
          </vue-accordion-item>
        </vue-accordion>
      </div>
      <div class="table-responsive table-responsive-md w-auto dashboard-table">
        <table class="table" :id="tableId">
          <thead class="c-table__head c-table__head--slim">
            <tr class="c-table__row">
              <!-- TODO add select all -->
              <th class="c-table__cell--head no-sort table-checkbox w-50px">
                <div class="c-choice c-choice--checkbox mb-0">
                  <input class="c-choice__input" id="tablecheck1" name="checkboxes" type="checkbox" />
                  <label class="c-choice__label" for="tablecheck1"></label>
                </div>
              </th>
              <th class="c-table__cell--head no-sort align-middle"></th>
              <th class="c-table__cell--head no-sort align-middle"></th>
              <th v-if="listDataObj.table.hover.isEnabled" class="c-table__cell c-table__cell--head no-sort">
                Menu
              </th>

              <!-- Table Header -->
              <th
                v-for="header in listDataObj.table.header"
                :key="header.id"
                class="c-table__cell c-table__cell--head"
                :class="{
                  'sort-class': header.isSortable,
                  'no-sort': !header.isSortable,
                  'text-center': header.rightAlign,
                  'w-120px': header.title === 'Quote #',
                }"
              >
                <template v-if="header.isSortable">
                  <div class="d-flex align-items-end">
                    <a :id="'table-header-' + header.title" v-if="header.isSortable" href="#">{{ header.title }}</a>
                    <div class="d-flex flex-column ml-2 small clickable">
                      <i class="fa fa-chevron-up"></i>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                  </div>
                </template>

                <template v-else>{{ header.title }}</template>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Table Row -->
            <template v-if="tableListData.length > 0">
              <tr v-for="data in tableListData" :key="tableId === 'datatable-list-admin' ? data.guid : data.id" class="c-table__row" :class="{rowSelected: isChecked(data.id)}">
                <!-- Checkboxes-->
                <td class="align-top table-checkbox">
                  <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                    <input class="c-choice__input" :id="data.id" name="checkboxes" type="checkbox" v-model="checked" :value="data.id" />
                    <label class="c-choice__label" :for="data.id"></label>
                  </div>
                </td>

                <td class="align-top">
                  <!-- Edit Link -->
                  <a @click.prevent="showPanel(data)" class="edit-link" target="_blank" :href="generateLink(data)" :id="`${tableId}-edit-button-row-id-${data.id}`">Edit</a>
                </td>

                <!-- Hover Menu / Quick Action -->
                <td class="text-left">
                  <i v-if="listDataObj.settings.isHoverOverRowEnabled" class="fa fa-sticky-note-o" @mouseover="getHoverInfo(data.id)" v-bind:id="'tooltip_' + data.id">
                    <b-tooltip v-bind:target="'tooltip_' + data.id" triggers="hover" placement="righttop">
                      <div>Description + Notes</div>
                      <p v-html="notesHoverInfo">No description/notes are available.</p>
                    </b-tooltip>
                  </i>
                </td>

                <!-- Standard Columns -->
                <td v-for="header in listDataObj.table.header" :key="header.id" class="align-top" :class="[{'text-right': header.rightAlign}, {'mw-300px': header.columnName == 'description'}]">
                  <span v-if="header.isHtmlColumn" v-html="tableColumnDataLookup(data, header.columnName)"></span>
                  <template v-else>
                    <span v-html="tableColumnDataLookup(data, header.columnName)"></span>

                    <!-- Added time inside date cell -->
                    <div v-if="header.columnName == 'createdDate' && tableColumnDataLookup(data, 'time')">
                      <small class="text-muted">{{ data.time }}</small>
                    </div>

                    <!-- Parent Relationship -->
                    <div class="d-flex align-items-start" v-if="header.columnName == 'name' && tableColumnDataLookup(data, 'parent')">
                      <div class="badge c-badge--xsmall ss-primary-light-bg">
                        {{ tableColumnDataLookup(data, "parent") }}
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="c-table__row">
                <td class="align-middle text-center" :colspan="listDataObj.table.header.length + 3">No data found</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {VueAccordion, VueAccordionItem} from "vue-accordion-styless";
  import SearchService from "@/services/search";
  import ApplicationService from "@/services/application";
  import {findIndex} from "lodash";
  const app = new ApplicationService();
  const global = new SearchService();

  export default {
    props: {
      listDataObj: {
        type: Object,
        required: true,
      },
      tableId: {
        type: String,
        default: "datatable",
      },
    },
    data() {
      return {
        notesHoverInfo: "",
        dispatchDate: new Date().toISOString().slice(0, 10),
        isDispatching: false,
        isExportingStarted: false,
        downloadLink: "",
        isExporting: false,
        exportFileResponse: "",
        dispatchResponseVisible: false,
        displayDispatchResponse: "",
        selectedTechnician: null,
        selectedAction: null,
        selectedActionId: 0,
        technicianList: [],
        isClose: true,
        checked: [],
        alignRight: true,
        pageTitle: null,
        actionList: [
          {id: 0, displayName: "Dispatch Technician"},
          {id: 1, displayName: "Export to Excel"},
        ],
      };
    },
    computed: {
      tableListData() {
        let list = this.$store.getters.getList;

        return list.data;
      },
    },
    methods: {
      generateLink(data) {
        const id = this.tableId === "datatable-list-admin" ? data.guid : data.id;
        return this.listDataObj.editAction.route.path + "/" + id;
      },
      isChecked(id) {
        let index = findIndex(this.checked, (item) => {
          return item === id;
        });

        return index >= 0;
      },
      async getHoverInfo(id) {
        let filter = {
          id: id,
          actionId: this.listDataObj.actionId,
        };

        let results = await global.getResults("global/hover", filter);

        if (results.success) {
          this.notesHoverInfo = results.resources;
        } else {
          this.notesHoverInfo = "";
        }
      },
      async loadData() {
        //If an id was specified in the url, then get the data and open the side panel
        if (this.$route.params.id) {
          let rowObj = this.tableListData.find((obj) => {
            if (this.tableId === "datatable-list-admin") {
              return obj.guid === this.$route.params.id.trim();
            } else {
              return obj.id == this.$route.params.id;
            }
          });

          // find the data if not found on our tablelistdata
          if (!rowObj) {
            let filter = {
              actionId: this.listDataObj.actionId,
              id: undefined,
            };

            if (this.$route.name === "client-details") {
              filter.intClientID = this.$route.params.id;
            } else if (this.$route.name === "employees-details") {
              filter.intEmployeeID = this.$route.params.id;
            } else if (this.$route.name === "purchase-order-details" || this.$route.name === "invoice-details") {
              filter.id = this.$route.params.id;
            } else if (this.$route.name === "quote-details") {
              filter.intQuoteID = this.$route.params.id;
            } else if (this.$route.name === "admin-details") {
              filter["cosmosdb.q"] = this.$route.params.id;
            }
            const search = await global.getResults("global/list", filter);
            const results = search.resources;
            rowObj = results.find((obj) => {
              if (this.tableId === "datatable-list-admin") {
                return obj.guid === this.$route.params.id.trim();
              } else {
                return parseInt(obj.id) === parseInt(this.$route.params.id);
              }
            });
          }
          this.showPanel(rowObj);
        }
      },
      showPanel(data) {
        if (!data) return;
        //Get the Route data passed from the controller
        let route = this.listDataObj.editAction.route;
        const id = this.tableId === "datatable-list-admin" ? data.guid : data.id;
        //Set Id of the currently selected/edit table item
        let currentPath = route.path + "/" + id;
        route.param.id = id;

        //Update the router
        if (currentPath !== this.$route.path) {
          this.$router.push({name: `${route.name}`, params: {id: `${id}`}});
        }
        //Pass to show panel for further processing
        app.showSidePanel(this.listDataObj, data, route);
      },
      tableColumnDataLookup(data, columnName) {
        return data[columnName];
      },
      onActionSelected() {},
      toggle() {
        this.isClose = !this.isClose;
      },
    },
    async mounted() {
      await this.loadData();
      this.pageTitle = this.$route.meta.gtm;
    },
    components: {VueAccordion, VueAccordionItem},
  };
</script>
