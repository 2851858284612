<template>
  <div class="px-3">
    <div class="row">
      <div class="col-xl-2 col-md-6 mb-2 px-0 pr-md-3">
        <input class="form-control" type="text" placeholder="Invoice/Job #/Client" v-model="query" @input="sendFilterChanges" />
      </div>
      <div class="col-xl-2 col-md-6 mb-2 px-0 pr-xl-3">
        <v-select class="w-100" placeholder="Type" :options="primarySelection" v-model="selectedPrimaryFilter" label="displayName" @input="loadSecondarySelection"></v-select>
      </div>
      <div class="col-xl-2 col-md-6 mb-2 px-0 pr-md-3">
        <v-select
          class="w-100"
          :placeholder="selectedPrimaryFilter ? `Select ${selectedPrimaryFilter.displayName}` : ''"
          :disabled="!selectedPrimaryFilter"
          v-model="selectedSecondaryFilter"
          :options="secondarySelection"
          :multiple="selectedPrimaryFilter && selectedPrimaryFilter.isMultiple"
          label="displayName"
          @input="sendFilterChanges"
        ></v-select>
      </div>
      <div class="col-xl-2 col-md-6 mb-2 px-0">
        <date-picker id="technician-report-filter-date-range" v-model="valueDate" type="date" format="YYYY-MM-DD" value-type="format" lang="en" range placeholder="Select Date Range" class="w-100" />
      </div>
      <div class="col-xl-auto col-12 flter-group-buttons mb-2 mb-2 ml-lg-auto pl-0 pr-0 d-flex">
        <b-form-group class="mb-0 custom-group-radio-buttons technician-dashboard-group-buttons w-100" v-slot="{ariaDescribedby}">
          <b-form-radio-group
            class="w-100"
            id="filter-group-range-radio"
            button-variant="outline-primary"
            v-model="selectedGroupRange"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radios-btn-default"
            buttons
          >
          </b-form-radio-group>
        </b-form-group>
        <div class="text-right ml-3">
          <button class="btn c-btn-outlined-info btn-sm" id="filters-download-excel" @click="downloadExcel"><i class="fa fa-download u-text-large"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  let global = new GlobalServices();
  export default {
    name: "ListFilters",
    props: {
      primarySelection: {
        type: Array,
        default: () => {
          return [
            {displayName: "Invoice Status", code: "status", actionId: MODULE.INVOICE.UX.InvoiceStatus, isMultiple: false},
            {displayName: "Invoice Label", code: "label", actionId: MODULE.INVOICE.UX.InvoiceLabels, isMultiple: false},
            {displayName: "Created By", code: "created", actionId: MODULE.INVOICE.ActionId, columnName: "createdBy", isMultiple: true},
          ];
        },
      },
    },
    data() {
      return {
        query: null,
        valueDate: [],
        selectedPrimaryFilter: null,
        selectedSecondaryFilter: null,
        secondarySelection: [],
        selectedGroupRange: null,
        options: [
          {text: "This Week", value: "thisweek"},
          {text: "Last Week", value: "lastweek"},
          {text: "This Month", value: "thismonth"},
          {text: "This Year", value: "thisyear"},
        ],
        timer: null,
      };
    },
    created() {
      this.MODULE = MODULE;
    },
    watch: {
      valueDate(value) {
        if (value && value.length === 2 && value[0] !== "" && value[1] !== "") {
          this.selectedGroupRange = null;
        }
        this.sendFilterChanges();
      },
      selectedGroupRange(value) {
        if (value) {
          if (this.valueDate.length > 0) this.valueDate = [];
          this.sendFilterChanges();
        }
      },
    },
    methods: {
      async loadSecondarySelection(evt) {
        this.selectedSecondaryFilter = evt && evt.isMultiple ? [] : null;
        if (evt && evt.columnName) {
          this.secondarySelection = await global.getUXDropDownByColumnName(evt.actionId, evt.columnName);
        } else {
          this.secondarySelection = await global.getUXDropDown(evt.actionId);
        }
      },
      async downloadExcel() {
        const res = await global.getTableReportDataByActionId({
          actionId: MODULE.INVOICE.ActionId,
          id: 0,
          maximumRows: 100,
          ...this.$store.getters.getList.filter,
          export: "excel",
        });
        if (!Array.isArray(res)) window.open(res, "_blank");
      },
      sendFilterChanges() {
        const filter = {};

        if (this.query) {
          filter.q = this.query;
        }

        if (this.selectedPrimaryFilter && this.selectedSecondaryFilter) {
          if (this.selectedPrimaryFilter.code === "label") {
            filter.InvoiceLabelID = this.selectedSecondaryFilter.id;
          }
          if (this.selectedPrimaryFilter.code === "status") {
            filter.InvoiceStatusID = this.selectedSecondaryFilter.id;
          }
          if (this.selectedPrimaryFilter.code === "created") {
            const selectedSecondaryFilter = this.selectedSecondaryFilter.map((i) => i.displayName).toString();
            this.$set(filter, "i.createdBy", selectedSecondaryFilter);
          }
        }

        if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
          filter.StartDate = this.valueDate[0];
          filter.EndDate = this.valueDate[1];
        }

        if (this.selectedGroupRange) {
          filter.dateFilter = this.selectedGroupRange;
        }

        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.$store.dispatch("setFilter", filter);
        }, 500);
      },
    },
  };
</script>
